// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/utils/useRootLoader.ts"
);
import.meta.hot.lastModified = "1715264889095.3682";
}
// REMIX HMR END

import { useMatches } from '@remix-run/react'
import { loader as rootLoader, RootLoaderData } from '~storefront/root'

export function useRootLoader(): RootLoaderData {
  return useMatches().find((match) => match.id === 'root')!
    .data as RootLoaderData
}
